import React from 'react';
import { Form, Link, Button } from 'components';

type Props = {
  values: any,
  errors: any,
  isValidated: boolean,
  handleChange: (event: any) => void,
  handleSubmit: (event: any) => void,
}
const Step1: React.FC<Props> = ({
  values, errors, isValidated, handleChange, handleSubmit
}) => {
  return (
    <div className="register__step">
      <h2 className="register__step-title">Forgot your password?</h2>
      <h2 className="register__step-subtitle">No problem.</h2>
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group className="mb-5">
          <Form.Input
            name="email"
            placeholder="Email Address"
            className="text-center"
            value={values.email || ''}
            isValid={isValidated && Boolean(!errors.email)}
            isInValid={isValidated && Boolean(errors.email)}
            error={errors.email}
            onChange={handleChange}
            round
            required
          />
        </Form.Group>
        <Button
          large
          round
          className="mb-5"
          sizeX={'182px'}
        >
          Send a reset
        </Button>
      </Form>
      <div className="register__step-forgot-option">
        <Link
          to="/login"
          className="register__step-forgot-option-anchor"
        >
          Back to login
        </Link>
      </div>
    </div>
  );
};

export default Step1;
