import React from 'react';
import { useRecoilStateLoadable } from 'recoil';
import { navigate } from 'gatsby';
import { AuthPageWrapper, Link, BrandLogo } from 'components';
import ForgotPasswordForm from 'templates/authentication/forgotPassword';
import { AuthUserState } from 'data/auth';

const ForgotPassword: React.VFC = () => {
  const [user] = useRecoilStateLoadable(AuthUserState);

  if (user.state === 'hasValue' && user?.contents?.authenticated) {
    navigate('/dashboard');
  }

  return (
    <AuthPageWrapper>
      <Link to='/'>
        <BrandLogo className='register__logo' />
      </Link>
      <div className='register__steps'>
        <ForgotPasswordForm />
      </div>
      <div className='register__footer'>
        © {(new Date().getFullYear())} Voicio. Alright Reserved.
      </div>
    </AuthPageWrapper>
  );
};

export default ForgotPassword;
