import React from 'react';
import { ButtonLink } from 'components';

const Step2 = ({ email }) => {
  return (
    <div className="register__step">
      <h2 className="register__step-title">Forgot your password?</h2>
      <p className="register__step-subtitle mt-0 mb-5">
        If you have a Voicio account registered to {email}, you will
        receive an email with a link to reset your Voicio password.
      </p>
      <ButtonLink
        large
        round
        to="/login"
        className="register__step-btn mb-3"
        sizeX={'182px'}
      >
        Back to Login
      </ButtonLink>
    </div>
  );
};

export default Step2;
