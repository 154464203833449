import React from 'react';
import { useMutation } from 'react-query';
import { useStep } from 'react-hooks-helper';
import { useForm } from 'components/hooks';
import Step1 from './step1';
import Step2 from './step2';
import { sendResetPasswordEmail } from 'data/auth';

const steps = [
  { id: 'step1' },
  { id: 'step2' },
];

function validate(values) {
  const errors: any = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }

  return errors;
};

const ForgotPassword: React.VFC = () => {
  const { step, navigation } = useStep({ initialStep: 0, steps });

  const mutation = useMutation(sendResetPasswordEmail, {
    onSuccess: (success: boolean) => {
      if (success) {
        navigation.next();
      };
    }
  });

  const onSubmit = (values) => {
    mutation.mutate(values.email);
  };

  const {
    values,
    errors,
    isValidated,
    handleChange,
    handleSubmit,
  } = useForm(onSubmit, validate);

  const { id } = step;
  const props = {
    values,
    errors,
    isValidated,
    handleChange,
    handleSubmit,
    navigation,
  };

  const renderStep = (id) => {
    switch (id) {
      case 'step1':
        return <Step1 {...props} />;
      case 'step2':
        return <Step2 email={values.email}/>;
      default:
        return null;
    }
  };

  return (
    <>
      {renderStep(id)}
    </>
  );
};

export default ForgotPassword;
